import React from 'react';
import {decodeHTML} from 'assets/helper.js';
import {graphql, Link, StaticQuery} from 'gatsby';

import Layout from 'components/Layout.js';
import Meta from 'components/Meta.js';
import Tile from 'components/Tile.js';

export default class Blog extends React.Component {
    renderTiles(posts) {
        return posts.map(({node}) => (
            <Tile
                copy={node.acf && node.acf.excerpt}
                image={node.master_image}
                isVideo={node.categories.some(
                    (cat) => cat.wordpress_id === 1149,
                )}
                key={node.path}
                title={decodeHTML(node.title)}
                to={node.path}
            />
        ));
    }

    render() {
        return (
            <StaticQuery
                query={graphql`
                    fragment postNode on wordpress__POST {
                        acf {
                            excerpt
                        }
                        categories {
                            description
                            slug
                            wordpress_id
                        }
                        master_image: jetpack_featured_media_url
                        path
                        title
                        wordpress_id
                    }
                    {
                        blogPage: wordpressPage(wordpress_id: {eq: 53}) {
                            acf {
                                subtitle
                            }
                            title
                        }
                        posts: allWordpressPost(
                            filter: {
                                categories: {
                                    elemMatch: {
                                        wordpress_id: {
                                            nin: [
                                                106132
                                                1149
                                                680061024
                                                680061025
                                            ]
                                        }
                                    }
                                }
                            }
                            limit: 4
                            sort: {fields: date, order: DESC}
                        ) {
                            edges {
                                node {
                                    ...postNode
                                }
                            }
                        }
                        morePosts: allWordpressPost(
                            filter: {
                                categories: {
                                    elemMatch: {
                                        wordpress_id: {
                                            nin: [
                                                106132
                                                1149
                                                680061024
                                                680061025
                                            ]
                                        }
                                    }
                                }
                            }
                            skip: 4
                            sort: {fields: date, order: DESC}
                        ) {
                            edges {
                                node {
                                    ...postNode
                                }
                            }
                        }
                        videoCategory: wordpressCategory(
                            wordpress_id: {eq: 1149}
                        ) {
                            description
                            name
                        }
                        videos: allWordpressPost(
                            filter: {
                                categories: {
                                    elemMatch: {wordpress_id: {in: 1149}}
                                }
                            }
                            limit: 4
                            sort: {fields: date, order: DESC}
                        ) {
                            edges {
                                node {
                                    ...postNode
                                }
                            }
                        }
                        categories: allWordpressCategory(
                            filter: {count: {ne: 0}}
                        ) {
                            edges {
                                node {
                                    name
                                    path
                                    wordpress_id
                                }
                            }
                        }
                    }
                `}
                render={(data) => (
                    <Layout className="blog contain">
                        <Meta
                            description={data.blogPage.acf.subtitle}
                            title={data.blogPage.title}
                        />
                        <section className="grid">
                            <article>
                                <h1>{data.blogPage.title}</h1>
                                <p>{data.blogPage.acf.subtitle}</p>
                                <ul>
                                    {data.categories.edges.map(({node}) => (
                                        <li key={node.wordpress_id}>
                                            <Link to={node.path}>
                                                {node.name}
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            </article>
                            {this.renderTiles(data.posts.edges)}
                        </section>
                        <section className="grid grid-highlighted">
                            <article>
                                <h1>{data.videoCategory.name}</h1>
                                <p>{data.videoCategory.description}</p>
                            </article>
                            {this.renderTiles(data.videos.edges)}
                        </section>
                        <section className="grid">
                            {this.renderTiles(data.morePosts.edges)}
                        </section>
                    </Layout>
                )}
            />
        );
    }
}
